<template>
  <div class="text-left">
    <label class="text text-m-small-bold">Phone number</label>
    <v-text-field
      v-model="phoneNumber"
      v-mask="'(###) ###-####'"
      height="56"
      dense
      outlined
      placeholder="Enter your phone number"
      :rules="[rules.required, rules.phoneNumber]"
      required
    />
  </div>
</template>

<script>
import ValidatorComponent from '@/components/common/ValidatorComponent';
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidatorComponent },
  data: () => ({
    phoneNumber: '',
    rules: ValidatorComponent.data().rules,
  }),
  watch: {
    phoneNumber: function (newPhoneNumber) {
      this.$emit('phone-number', newPhoneNumber);
    },
  },
}
</script>
<style scoped>
  .text-left >>> input::placeholder {
    color: var(--v-grey-darken4) !important;
  }

  .v-text-field--outlined >>> fieldset {
    border: 1px solid var(--v-grey-darken4);
    background-color: transparent !important;
  }

  .v-text-field--outlined.v-input--has-state >>> fieldset {
    border: 2px solid currentColor;
  }
</style>